import React, { Component } from "react";
import Hero from "../Hero";

export default class DataScience extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <!-- ======= DevOps Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>DevOps</h2>
            </div>

            <div className="row content">
              <div
                className="col-lg-12"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                Comming Soon
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End DevOps Section --> */}
      </React.Fragment>
    );
  }
}
