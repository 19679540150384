import React from 'react'
import ServiceData from './data/serviceData'

const Services = () => {
    const path = window.location.pathname.split('/');
    const slug = path[path.length - 1];
    const service = ServiceData.find((service) => service.slug === slug);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            {/* <!-- ======= Services Section ======= --> */}

            <section id="services" className="services">
                <div className="banner">
                    <h1>{service.title}</h1>
                    <p>{service.subtitle}</p>
                </div>
                <div className="container mt-4">
                    <div className="section-title">
                        <h3 style={{
                            fontSize: '1.5rem',
                            fontWeight: '900',
                            letterSpacing: '0.1rem',
                            textAlign: 'left',
                            color: '#1a1a1a',
                            marginBottom: '0',
                        }}>{service.long_title}</h3>
                    </div>

                    <p className='text-left' dangerouslySetInnerHTML={{ __html: service.data }}></p>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Services