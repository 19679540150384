import React, { useState } from 'react';

const Testimonial = ({ testimonial }) => {
    const [showFullTestimonial, setShowFullTestimonial] = useState(false);
    const wordLimit = 25;

    const toggleTestimonialDisplay = () => setShowFullTestimonial(!showFullTestimonial);

    const truncateTestimonial = (text, wordLimit) => {
        return text.split(" ").slice(0, wordLimit).join(" ") + (text.split(" ").length > wordLimit ? "..." : "");
    };

    return (
        <div className="testimonial-wrap">
            <div className="testimonial-item">
                <img src={testimonial.imageSrc} className="testimonial-img" alt="" />
                <h3>{testimonial.name}</h3>
                <h4>{testimonial.position}</h4>
                <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    {showFullTestimonial ? testimonial.testimonial : truncateTestimonial(testimonial.testimonial, wordLimit)}
                    {
                        testimonial.testimonial.split(" ").length > wordLimit &&
                        <span
                            style={{
                                cursor: "pointer",
                                fontWeight: "bold"
                            }}
                            onClick={toggleTestimonialDisplay} className="testimonial-toggle">
                            {showFullTestimonial ? " Read Less" : " Read More"}
                        </span>
                    }
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
            </div>
        </div>
    );
};

export default Testimonial;
