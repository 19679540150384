import React, { Component } from 'react';
import Hero from './Hero';
import ServiceData from './data/serviceData';

export default class About extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about mt-3">
          <div className="container">
            <div className="section-title" data-aos="fade-down">
              <h2>About Us</h2>
            </div>

            <div className="row content">
              <div className="col-lg-12" data-aos="fade-down" data-aos-delay="150">
                <p>
                  Detayuno Analytics works with pioneer technology associates to deliver the
                  best explanation to address our clients’ business needs. Our end-to-end
                  service portfolio is wrapped around this partner expertise. Entering in
                  our circle of innovation connects administrations to our extended network
                  of advanced tools and technology. Our commitment to excellence is founded
                  upon a set of innate values that all Detayuno Analytics members respect and trust.
                </p>
                <p>
                  We believe in delivering high quality products.The growth of our company shows
                  “What we say, we deliver”.
                </p>
                <ul>
                  {ServiceData.slice(0, 8).map((service) => (
                    <li key={service.title} title={service.title}>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href={`/services/${service.slug}`}>
                        {service.short_title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <div className="row content">
              <div className="col-lg-6" data-aos="fade-down" data-aos-delay="150">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </p>
                <ul>
                  <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                  <li><i className="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
                  <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-down" data-aos-delay="300">
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                  velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                  culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <a href="#" className="btn-learn-more">Learn More</a>
              </div>
            </div> */}
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Team Section ======= --> */}
        <section id="team" className="team section-bg">
          <div className="container">

            <div className="section-title" data-aos="fade-down">
              <h2>Team</h2>
              {/* <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem</p> */}
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="100">

                  <div className="member-img">
                    <img src="assets/img/team/manoj.png" className="img-fluid1" alt="Manoj" />
                    <div className="social">
                      <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                      <a href="#"><i className="icofont-facebook"></i></a>
                      <a href="#"><i className="icofont-instagram"></i></a>
                      <a href="#"><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Manoj Kumar</h4>
                    <span>Director</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="200">

                  <div className="member-img">
                    <img src="assets/img/team/shekhar.png" className="img-fluid1" alt="Shekhar" />
                    <div className="social">
                      <a href=""><i class="fa-brands fa-x-twitter"></i></a>
                      <a href=""><i className="icofont-facebook"></i></a>
                      <a href=""><i className="icofont-instagram"></i></a>
                      <a href=""><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Shekhar Kumar</h4>
                    <span>Founder & CEO</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="300">
                  <div className="member-img">
                    <img src="assets/img/team/surinder.png" className="img-fluid1" alt="Surinder" />
                    <div className="social">
                      <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                      <a href="#"><i className="icofont-facebook"></i></a>
                      <a href="#"><i className="icofont-instagram"></i></a>
                      <a href="#"><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Surinder Batti</h4>
                    <span>Co-Founder</span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="100">
                  <div className="member-img">
                    <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                    <div className="social">
                      <a href=""><i class="fa-brands fa-x-twitter"></i></a>
                      <a href=""><i className="icofont-facebook"></i></a>
                      <a href=""><i className="icofont-instagram"></i></a>
                      <a href=""><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Walter White</h4>
                    <span>Chief Executive Officer</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="200">
                  <div className="member-img">
                    <img src="assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                    <div className="social">
                      <a href=""><i class="fa-brands fa-x-twitter"></i></a>
                      <a href=""><i className="icofont-facebook"></i></a>
                      <a href=""><i className="icofont-instagram"></i></a>
                      <a href=""><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Sarah Jhonson</h4>
                    <span>Product Manager</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="300">
                  <div className="member-img">
                    <img src="assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                    <div className="social">
                      <a href=""><i class="fa-brands fa-x-twitter"></i></a>
                      <a href=""><i className="icofont-facebook"></i></a>
                      <a href=""><i className="icofont-instagram"></i></a>
                      <a href=""><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>William Anderson</h4>
                    <span>CTO</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                <div className="member" data-aos="fade-down" data-aos-delay="400">
                  <div className="member-img">
                    <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                    <div className="social">
                      <a href=""><i class="fa-brands fa-x-twitter"></i></a>
                      <a href=""><i className="icofont-facebook"></i></a>
                      <a href=""><i className="icofont-instagram"></i></a>
                      <a href=""><i className="icofont-linkedin"></i></a>
                    </div>
                  </div>
                  <div className="member-info">
                    <h4>Amanda Jepson</h4>
                    <span>Accountant</span>
                  </div>
                </div>
              </div>

            </div> */}
          </div>
        </section>
        {/* <!-- End Team Section --> */}

        {/* <!-- ======= Technologies Section ======= --> */}
        <section id="features" className="features">
          <div className="container">

            <div className="section-title" data-aos="fade-down">
              <h2>Technologies</h2>
              {/* <p>We are expert in the following technologies.</p> */}
            </div>

            <div className="row" data-aos="fade-down" data-aos-delay="300">
              <div className="col-lg-3 col-md-4">
                <div className="icon-box">
                  <i className="lni lni-python" style={{ color: '#ffbb2c' }}></i>
                  <h3 title="Python">Python</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                <div className="icon-box">
                  <img src="https://img.icons8.com/cotton/32/000000/artificial-intelligence.png" />

                  <h3 title="Artifical Intelligence">Artifical Intelligence</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                <div className="icon-box">
                  <img src="https://img.icons8.com/color/32/000000/learning.png" />
                  <h3 title="Machine Learning">Machine Learning</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                <div className="icon-box">
                  <img src="https://img.icons8.com/color/32/000000/hadoop-distributed-file-system.png" />
                  <h3 title="Big Data">Big Data</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-infinite" style={{ color: '#ffa76e' }}></i>
                  <h3 title="devops">Devops</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <img src="https://img.icons8.com/dusk/32/000000/scala.png" />
                  <h3 title="Spark With Scala">Spark With Scala</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <img src="https://img.icons8.com/color/32/000000/jenkins.png" />
                  <h3 title="Jenkins">Jenkins</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-shopify" style={{ color: '#b20969' }}></i>
                  <h3 title="Shopify">Shopify</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-react" style={{ color: '#11dbcf' }}></i>
                  <h3 title="React">React</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-angular" style={{ color: '#FF0000' }}></i>
                  <h3 title="Angular">Angular</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-nodejs" style={{ color: '#29cc61' }}></i>
                  <h3 title="Node">Node</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-php" style={{ color: '#47aeff' }}></i>
                  <h3 title="PHP">PHP</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="lni lni-wordpress" style={{ color: '#4233ff' }}></i>
                  <h3 title="Wordpress">Wordpress</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="ri-anchor-line" style={{ color: '#b2904f' }}></i>
                  <h3 title="Woocommerce">Woocommerce</h3>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <img src="https://img.icons8.com/windows/32/000000/django.png" />
                  <h3 title="Django">Django</h3>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 mt-4">
                <div className="icon-box">
                  <i className="bx bxs-flask" style={{ color: '#e80368' }}></i>
                  <h3 title="Flask">Flask</h3>
                </div>
              </div>

            </div>
          </div>
        </section>
        {/* <!-- End Technologies Section --> */}

      </React.Fragment>
    )
  }
}