import React, { useState } from 'react';
import expertiseData from "./data/expertise.json";

const chunkArray = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
};

const ExpertiseSection = () => {
  const [searchQuery, setSearchQuery] = useState('');

  let mergedItems = [];
  expertiseData.forEach(section => {
    mergedItems = mergedItems.concat(section.items);
  });

  mergedItems.sort((a, b) => a.title.localeCompare(b.title));

  const filteredItems = mergedItems.filter(item =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const itemsPerRow = 4;
  const rows = chunkArray(filteredItems, itemsPerRow);

  return (
    <React.Fragment>
      <section id="services" className="services">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4"></div>
            <div className="col-md-4 section-title" data-aos="fade-down">
              <h2>Expertise</h2>
              <p>We offer expertise to grow your business.</p>
            </div>
            <div className="col-md-4 my-0">
              <input className="form-control" type="search" placeholder="Search the expertise..." value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} />
            </div>
          </div>
          {filteredItems.length > 0 ? (
            rows.map((row, rowIndex) => (
              <div className="row" key={rowIndex} style={{ marginBottom: "20px" }}>
                {row.map((item, index) => (
                  <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" key={index}>
                    <div className="icon-box" data-aos="fade-down" data-aos-delay="100">
                      <div className="icon">
                        {item.icon ?
                          <img src={item.icon} alt="icon" /> :
                          <i className="bx bxs-data"></i>
                        }
                      </div>
                      <h4 className="title">{item.title}</h4>
                      <p className="description">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))) : (
            <div className="row">
              <div className="col text-center text-danger">
                <strong>No expertise found matching your search criteria.</strong>
              </div>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default ExpertiseSection;
