import React from 'react'
import { Link } from "react-router-dom"

const SolutionCard = ({ imgSrc, title, description, link }) => {
    return (
        <div className="card p-3 d-flex justify-content-center align-items-center">
            <img
                src={imgSrc}
                className="img-fluid"
                alt={title}
                data-aos="zoom-in"
            />
            <h4 className="mt-2">{title}</h4>
            <p>{description}</p>
            <Link className="btn btn-primary" to={link}>Read More</Link>
        </div>
    )
}

export default SolutionCard