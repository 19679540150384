import React, { Component } from "react";
import servicesData from "./Component/data/serviceData"
import industriesData from "./Component/data/industriesData"

export default class Header extends Component {
  render() {
    return (
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <div className="logo mr-auto">
            <a href="/">
              <img
                src="/assets/img/logos/logo1.png"
                // width="100"
                // height="80"
                alt="Detayuno Analytics"
                className="img-fluid"
              />
            </a>
          </div>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li className="active">
                <a href="/">Home</a>
              </li>

              <li className="active">
                <a href="/expertise">Expertise</a>
              </li>
              <li className="drop-down">
                <a style={{ cursor: "pointer" }}>Services</a>
                <ul>
                  {servicesData.map((services) => (
                    <li key={services.title} title={services.title}>
                      <a href={`/services/${services.slug}`}>{services.short_title}</a>
                    </li>
                  ))}
                </ul>
              </li>

              <li>
                <a href="/about">About</a>
              </li>

              <li>
                <a href="/contact-us">Contact</a>
              </li>

              <li className="drop-down">
                <a style={{ cursor: "pointer" }}>Industries</a>
                <ul>
                  {industriesData.map((industries) => (
                    <li key={industries.title} title={industries.title}>
                      <a href={`/industries/${industries.slug}`}>{industries.title}</a>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="drop-down">
                <a href="">Training</a>
                <ul>
                  <li>
                    <a href="/data-science">Data Science</a>
                  </li>
                  <li>
                    <a href="/python">Python</a>
                  </li>
                  <li>
                    <a href="/hadoop">Hadoop</a>
                  </li>
                  <li>
                    <a href="/react-js">React JS</a>
                  </li>
                  <li>
                    <a href="/dev-ops">DevOps</a>
                  </li>
                </ul>
              </li>
              {/* <li className="get-started"><a href="#about">Get Started</a></li> */}
            </ul>
          </nav>{" "}
          {/* <!-- .nav-menu --> */}
        </div>
      </header>
    );
  }
}
