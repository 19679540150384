import React, { Component } from "react";
import ServiceData from "./Component/data/serviceData";
import IndustriesData from "./Component/data/industriesData";

const Footer = () => {
  const [userEmail, setUserEmail] = React.useState("");
  return (
    <footer id="footer">
      <div className="footer-newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h4>Join Our Newsletter</h4>
              <form
                // action="https://api.web3forms.com/submit" method="POST"
                className="php-email-form"
              >
                <input type="email" name="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} placeholder="Enter your Email" />
                <input type="submit" value="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>Detayuno Analytics</h3>
              <p>
                S.C.O.84, Main Road <br />
                Sector-58, Shahi Majra <br />
                S.A.S. Nagar,Mohali(Pb.) <br />
                <br />
                <strong>Phone:</strong> +91 9878 667 667
                <br />
                <strong>Email:</strong> sales@detayuno.com
                <br />
              </p>
              <div className="social-links mt-3">
                <a
                  href="https://wa.me/message/WK2KM4YB2OBAK1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whatsapp"
                >
                  <i className="bx bxl-whatsapp"></i>
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook"
                >
                  <i className="bx bxl-facebook"></i>
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin"
                >
                  <i className="bx bxl-linkedin"></i>
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 footer-links">
              <h4 className="text-center">Services</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <ul>
                    {ServiceData.slice(0, 7).map((service) => (
                      <li key={service.title} title={service.title}>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <a href={`/services/${service.slug}`}>
                          {service.short_title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6">
                  <ul>
                    {ServiceData.slice(7, 14).map((service) => (
                      <li key={service.title} title={service.title}>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <a href={`/services/${service.slug}`}>
                          {service.short_title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Industries</h4>
              <ul>
                {IndustriesData.map((industries) => (
                  <li key={industries.title} title={industries.title}>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href={`/industries/${industries.slug}`}>
                      {industries.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright {new Date().getFullYear()}
          . All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;