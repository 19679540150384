import React from 'react'
import ServiceData from './data/industriesData'

const Industries = () => {
    const path = window.location.pathname.split('/');
    const slug = path[path.length - 1];
    const service = ServiceData.find((service) => service.slug === slug);

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <React.Fragment>
            {/* <!-- ======= Industries Section ======= --> */}

            <section id="services" className="services">
                <div className="banner">
                    <h1>{service.long_title}</h1>
                </div>
                <div className="container mt-4">
                    <div className="section-title">
                        <h3 style={{
                            fontSize: '1.5rem',
                            fontWeight: '900',
                            letterSpacing: '0.1rem',
                            textAlign: 'left',
                            color: '#1a1a1a',
                            marginBottom: '0',
                        }}>{service.heading}</h3>
                    </div>

                    <p className='text-left' dangerouslySetInnerHTML={{ __html: service.data }}></p>

                    <button className="btn btn-primary mt-3" onClick={() =>
                        window.location.href = '/contact-us'
                    }>Schedule a Consultation</button>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Industries