const IndustriesData = [
    {
        title: "Financial and Insurance",
        long_title: "Elevate Your Financial and Insurance Operations",
        heading: "Tailored Solutions for Finance and Insurance Sectors",
        image: "",
        slug: "financial-and-insurance",
        data: `<h3 class="title">Why Choose Us?</h3>
        
        <p>Tailored Financial Solutions:</p>
        
        <p>Enhance efficiency, reduce operational costs, and gain a competitive edge with our suite of financial solutions designed specifically for the unique challenges of the financial sector.</p>
        
        <p>Innovative Insurance Services:</p>
        
        <p>Stay ahead in the insurance landscape with cutting-edge services. From advanced risk management to seamless claims processing, our offerings optimize processes and deliver exceptional value.</p>
        
        <p>Proven Track Record:</p>
        
        <p>Explore success stories of businesses in the financial and insurance sectors that have benefited from our expertise. [Link to Case Studies/Testimonials]</p>
        
        <p>Compliance and Security:</p>
        
        <p>Rest assured, our services comply with the highest industry standards, ensuring the security and confidentiality of your sensitive data.</p>
        
        <h3 class="title">Our Approach</h3>
        
        <p>Customized Solutions:</p>
        
        <p>We take a personalized approach to deliver solutions that align perfectly with your business objectives. Our team works closely with you to understand your challenges and develop strategies for sustainable growth.</p>
        
        <h3 class="title">Let's Transform Together</h3>
        
        <p>Ready to explore the future of finance and insurance with Detayuno Analytics? Schedule a consultation to discuss how we can bring innovation and efficiency to your operations.</p>
        
        <p>Thank you for considering Detayuno Analytics. We look forward to the opportunity to contribute to the success of your business.</p>
        `,
    },
    {
        title: "Government and Public Sector",
        long_title: "Transforming Governance with Detayuno Analytics",
        heading: "Tailored Solutions for Government and Public Sector",
        image: "",
        slug: "government-and-public-sector",
        data: `<h3 class="title">Why Choose Us?</h3>

        <p>Compliance and Transparency:</p>
        
        <p>Navigate the complexities of governance with confidence. Our solutions are designed to meet stringent compliance standards and enhance transparency in public sector operations.</p>
        
        <p>Public Service Innovation:</p>
        
        <p>We bring innovative technologies to the public sector, facilitating better service delivery and responsiveness. Explore how our solutions can elevate public service standards.</p>
        
        <p>Proven Public Sector Success:</p>
        
        <p>Discover success stories of government and public sector entities that have achieved efficiency and effectiveness through our solutions. [Link to Case Studies/Testimonials]</p>
        
        <p>Data Security for Public Trust:</p>
        
        <p>Ensuring the security and privacy of public data is our top priority. Rest assured, our services adhere to the highest standards to maintain public trust.</p>
        
        <h3 class="title">Our Approach</h3>
        
        <p>Customized Government Solutions:</p>
        
        <p>We understand the unique needs of government and public sector entities. Our team collaborates closely with you to develop tailored solutions that enhance public service delivery.</p>
        
        <h3 class="title">Partner for Public Impact</h3>
        
        <p>Ready to transform governance with Detayuno Analytics? Schedule a consultation to discuss how our innovative solutions can contribute to the success of your government and public sector initiatives.</p>
        
        <p>Thank you for considering Detayuno Analytics. We look forward to the opportunity to support your mission for public impact.</p>
        `,
    },
    {
        title: "Healthcare and Life Science",
        long_title: "Revolutionizing Healthcare with Detayuno Analytics",
        heading: "Tailored Solutions for Healthcare and Life Sciences",
        image: "",
        slug: "healthcare-and-life-science",
        data: `<h3 class="title">Why Choose Us?</h3>

        <p>Precision Healthcare Solutions:</p>
        
        <p>Elevate patient care with precision healthcare solutions designed to enhance diagnostics, treatment planning, and overall healthcare outcomes.</p>
        
        <p>Compliance and Data Security:</p>
        
        <p>We understand the critical importance of compliance in the healthcare sector. Our solutions adhere to the highest standards, ensuring data security and patient confidentiality.</p>
        
        <p>Innovative Life Sciences Support:</p>
        
        <p>From research to development, our innovative solutions support advancements in life sciences. Explore how our technology contributes to breakthroughs in healthcare and life sciences.</p>
        
        <p>Patient-Centric Approach:</p>
        
        <p>Our focus is on improving patient experiences. Learn how our solutions contribute to patient-centric care, fostering positive outcomes and satisfaction.</p>
        
        <h3 class="title">Our Approach</h3>
        
        <p>Customized Healthcare Solutions:</p>
        
        <p>Collaborate with us to develop tailored solutions that meet the unique challenges of healthcare and life sciences. Our team is dedicated to delivering technology that aligns with your mission.</p>
        
        <h3 class="title">Transforming Healthcare Together</h3>
        
        <p>Ready to revolutionize healthcare with Detayuno Analytics? Schedule a consultation to discuss how our solutions can positively impact patient care, research, and healthcare operations.</p>
        
        <p>Thank you for considering Detayuno Analytics. We look forward to the opportunity to contribute to the advancement of healthcare and life sciences.</p>
        `,
    },
    {
        title: "High Tech and FinTech",
        long_title: "Unleashing Innovation with Detayuno Analytics",
        heading: "Cutting-Edge Solutions for High Tech and FinTech",
        image: "",
        slug: "high-tech-and-fintech",
        data: `<h3 class="title">Why Choose Us?</h3>

        <p>Leading in High Tech Solutions:</p>
        
        <p>At Detayuno Analytics, we drive innovation in the high tech sector. Our solutions leverage cutting-edge technology to propel your business into the future.</p>
        
        <p>FinTech Expertise:</p>
        
        <p>Stay ahead in the dynamic world of finance with our specialized FinTech expertise. Explore how our solutions redefine financial processes, transactions, and customer experiences.</p>
        
        <p>Agile and Adaptive:</p>
        
        <p>In rapidly evolving industries, agility is key. Our solutions are designed to be adaptive, ensuring you stay ahead of market trends and technological advancements.</p>
        
        <p>Security at the Core:</p>
        
        <p>Security is paramount, especially in FinTech. Rest easy knowing that our solutions prioritize the highest standards of data security and compliance.</p>
        
        <h3 class="title">Our Approach</h3>
        
        <p>Customized High Tech and FinTech Solutions:</p>
        
        <p>Collaborate with our team to develop customized solutions that align with the unique challenges and opportunities in high tech and FinTech.</p>
        
        <h3 class="title">Powering Future Technologies</h3>
        
        <p>Ready to unleash innovation in high tech and FinTech with Detayuno Analytics? Schedule a consultation to explore how our solutions can redefine your technological landscape and financial operations.</p>
        
        <p>Thank you for considering Detayuno Analytics. We look forward to being your partner in advancing high tech and FinTech.</p>`,
    },
    {
        title: "Professional Services and Construction & Engineering",
        long_title: "Elevate Your Business with Detayuno Analytics",
        heading: "Tailored Solutions for Professional Services and Construction & Engineering",
        image: "",
        slug: "professional-services-and-construction-and-engineering",
        data: `<h3 class="title">Why Choose Us?</h3>
        
        <p>Expertise in Professional Services:</p>
        
        <p>Detayuno Analytics brings a wealth of experience in providing tailored solutions for professional services. Discover how our technology enhances efficiency and client satisfaction in your industry.</p>
        
        <p>Precision in Construction & Engineering:</p>
        
        <p>In the dynamic field of construction and engineering, precision matters. Explore our solutions designed to streamline project management, boost collaboration, and deliver successful outcomes.</p>
        
        <p>Reliability in Service Delivery:</p>
        
        <p>We understand the importance of reliability in professional services. Our solutions are crafted to ensure consistent, high-quality service delivery that exceeds client expectations.</p>
        
        <p>Innovation in Construction Tech:</p>
        
        <p>Stay at the forefront of construction technology with our innovative solutions. From project planning to execution, experience the transformative power of our technology in the construction and engineering sector.</p>
        
        <h3 class="title">Our Approach</h3>
        
        <p>Customized Solutions for Your Industry:</p>
        
        <p>Collaborate with our team to develop customized solutions that align perfectly with the unique challenges and goals of professional services, construction, and engineering.</p>
        
        <h3 class="title">Transforming Industries, One Solution at a Time</h3>
        
        <p>Ready to elevate your business with Detayuno Analytics? Schedule a consultation to explore how our solutions can enhance your professional services or construction and engineering operations.</p>
        
        <p>Thank you for considering Detayuno Analytics. We look forward to being your partner in success.</p>`,
    },
    {
        title: "Media, Entertainment & Telecommunications",
        long_title: "Igniting Creativity with Detayuno Analytics",
        heading: "Tailored Solutions for Media, Entertainment, and Telecommunications",
        image: "",
        slug: "media-entertainment-and-telecommunications",
        data: `<h3 class="title">Why Choose Us?</h3>
        
        <p>Innovative Media Solutions:</p>
        
        <p>Detayuno Analytics is your partner in media innovation. Explore our solutions designed to amplify creativity, streamline production, and elevate content delivery in the ever-evolving media landscape.</p>
        
        <p>Entertainment Industry Excellence:</p>
        
        <p>In the world of entertainment, excellence is non-negotiable. Discover how our cutting-edge technology enhances production workflows, content distribution, and audience engagement.</p>
        
        <p>Telecommunications Transformation:</p>
        
        <p>Stay ahead in telecommunications with our transformative solutions. From network optimization to customer experience, our technology is engineered to meet the demands of the rapidly evolving telecom industry.</p>
        
        <p>Adaptive to Industry Trends:</p>
        
        <p>In dynamic industries, adaptability is key. Our solutions are crafted to adapt seamlessly to changing trends, ensuring you stay ahead in media, entertainment, and telecommunications.</p>
        
        <h3 class="title">Our Approach</h3>
        
        <p>Customized Solutions for Your Industry:</p>
        
        <p>Collaborate with our team to develop customized solutions that align with the unique challenges and opportunities in media, entertainment, and telecommunications.</p>
        
        <h3 class="title">Elevate Your Industry Presence</h3>
        
        <p>Ready to ignite creativity and transform your operations with Detayuno Analytics? Schedule a consultation to explore how our solutions can propel your media, entertainment, or telecommunications business forward.</p>
        
        <p>Thank you for considering Detayuno Analytics. We look forward to being your partner in innovation.</p>`,
    }
]

export default IndustriesData;