import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/* Web application components */
import Home from "./Layout/Component/Home";
import Expertise from "./Layout/Component/Expertise";
import Industries from "./Layout/Component/Industries";
import Services from "./Layout/Component/Services";
// import Blogs from './Layout/Component/Blogs';
import DataScience from "./Layout/Component/training/DataScience";
import ReactJS from "./Layout/Component/training/ReactJS";
import DevOps from "./Layout/Component/training/DevOps";
import Python from "./Layout/Component/training/Python";
import Hadoop from "./Layout/Component/training/Hadoop";
import About from "./Layout/Component/About";
import ContactUs from "./Layout/Component/ContactUs";
import Careers from "./Layout/Component/Careers";
import NotFound from "./Layout/Component/NotFound";
import Post from "../src/testing/Post";

const siteRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/expertise" component={Expertise} />
        {/* <Route exact path='/blogs' component={Blogs} /> */}
        <Route exact path="/about" component={About} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/posts" component={Post} />

        {/* Training Routes */}
        <Route exact path="/data-science" component={DataScience} />
        <Route exact path="/python" component={Python} />
        <Route exact path="/hadoop" component={Hadoop} />
        <Route exact path="/react-js" component={ReactJS} />
        <Route exact path="/dev-ops" component={DevOps} />

        <Route path="/services" component={Services} />
        <Route path="/industries" component={Industries} />

        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default siteRoutes;
