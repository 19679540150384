import React, { Component } from "react";
import Hero from "../Hero";

export default class DataScience extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <!-- ======= Data Science Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Data Science</h2>
            </div>

            <div className="row content">
              <div
                className="col-lg-12"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <p>
                  With the popularity of Big Data increasing exponentially,
                  opportunities as Data Scientist / architects has been growing
                  in all major industry sectors etc. Training programs on Data
                  Science technology by Detayuno Analytics focuses on
                  empowering the students with the latest concepts and industry
                  specific topics. Our well experienced trainer and well planned
                  course materials ensures for 100% success in interviews.
                </p>
                <h4>
                  DATA SCIENCE (MACHINE LEARNING, DEEP LEARNING & AI) – Duration
                  – 60 Days (Mon – Sat)
                </h4>
                <div style={{ paddingLeft: 20, marginTop: 25 }}>
                  {/* Start of INTRODUCTION TO DATA SCEINCE */}
                  <h5>INTRODUCTION TO DATA SCEINCE</h5>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i> Need of Data
                      Science
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i> History of Data
                      Science
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i> What is Data
                      Science
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i> Data Science vs
                      Data Analytics
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i> Data Mining
                    </li>
                  </ul>
                  {/* End of INTRODUCTION TO DATA SCEINCE */}

                  {/* Start of INTRODUCTION TO MACHINE LEARINING */}
                  <h5>INTRODUCTION TO MACHINE LEARINING</h5>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i> What is machine
                      learning?
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i> Types of learning:
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i> Supervised
                          Machine Learning
                          <i className="bx bx-chevron-right"></i> Unsupervised
                          Machine Learning
                        </li>
                      </ul>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i> Machine learning
                      algorithms:
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i> Flow of
                          Supervised and Unsupervised Machine Learning
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Simple Linear
                          Regression
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Multiple
                          Linear Regression
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Logistic
                          Regression
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> K-Nearest
                          Neighbour
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Support Vector
                          Machine
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Decision Tree
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Random Forest
                          - Ensemble Machine Learning
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Naïve Bayes
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Clustering:
                          <ul>
                            <li>
                              <i className="bx bx-chevron-right"></i> K-Means
                            </li>
                            <li>
                              <i className="bx bx-chevron-right"></i> Principle
                              Component Analysis
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  {/* End of INTRODUCTION TO MACHINE LEARINING */}

                  {/* Start of PYTHON */}
                  <h5>PYTHON</h5>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <strong>Basics:</strong>
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i> Overview
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Environment
                          Setup
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Syntax
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Variables
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Data Types
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Type
                          Conversion
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Operators
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Decision
                          Making
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Loops
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Numbers
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Strings
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Functions
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Date & Time
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Exception
                          Handling
                        </li>
                      </ul>
                    </li>

                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <strong>Intermediate:</strong>
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i> Lists
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> List
                          Manipulation
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> List Methods
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Packages
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Tuples
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Dictionary
                          Manipulation
                        </li>
                      </ul>
                    </li>

                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <strong>Advanced:</strong>
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i> OOPS
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Database
                          Connectivity with MySQL
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> File System
                        </li>
                      </ul>
                    </li>

                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <strong>Data Science Essentials:</strong>
                      <li>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <strong>Numpy:</strong>
                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i> Introduction
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Numpy
                            Package{" "}
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Ndarray
                            Object
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Data Types
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Array
                            Attributes
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Array from
                            Numerical Ranges
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Indexing &
                            Slicing
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Advanced
                            Indexing
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Iterating
                            over array
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Array
                            Manipulation
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> String
                            Functions
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Arithmetic
                            Operations
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Statistical
                            Functions
                          </li>
                        </ul>
                      </li>
                      <li>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <strong>Pandas:</strong>
                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i> Introduction
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Pandas
                            Package{" "}
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Series
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> DataFrame
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Panel
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Descriptive
                            Statistics
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Indexing and
                            Selecting Data
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Iteration
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Sorting
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Aggregations
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Missing Data
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> GroupBy
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i>{" "}
                            Merging/Joining
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i>{" "}
                            Concatenation
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Date
                            Functionality
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Pandas –
                            Visualization
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Pandas – IO
                            Tools
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> CSV to
                            DataFrame
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Loc and iloc
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> DataFrame
                            Filtering
                          </li>
                        </ul>
                      </li>
                      <li>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <strong>Manipulating DataFrames with Pandas:</strong>
                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i> Extracting
                            and Transforming Data
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Reshaping
                            Data{" "}
                          </li>
                          <li>
                            <i className="bx bx-chevron-right"></i> Grouping
                            Data
                          </li>
                        </ul>
                      </li>
                      <li>
                        <i className="bx bx-chevron-right"></i>{" "}
                        <strong>Manipulating DataFrames with Pandas:</strong>
                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i>{" "}
                            <strong>MatPlotlib:</strong>
                            <ul>
                              <li>
                                <i className="bx bx-chevron-right"></i> Bar
                                Graph
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Histogram
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Scatter
                                Plot
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Pie
                                Chart
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <strong>
                        Statistics and Mathematical Essentials for Data Science:
                      </strong>
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i> Measure of
                          Central Tendency
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Mean
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Mode
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Median
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Range
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Inter Quartile
                          Range
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Variance
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Standard
                          Deviation
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Correlation
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Regression
                          Models in Machine Learning
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Residuals
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Correlation
                          Coefficients (Pearson)
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Accuracy
                          Measurement
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Least Square
                          Regression
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Root Mean
                          Square Error
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Coefficient of
                          Determination (R2 Score)
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Cost Function
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Gradient
                          Descent
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Hypothesis
                          Testing and p-values
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> T-values
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Z-score
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Create Dummy
                          Variables
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Cross
                          Validation
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Confusion
                          Matrix
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Compute
                          Precision, Recall, F-Measure and Support
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> TPR, FPR, FNR,
                          TNR
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Accuracy
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Learning rate
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Sensitivity
                          and Specificity
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> ROC Curve
                          (Receiver Operating Characteristic)
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Receiver
                          Operating Characteristic (ROC) curves
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Are under the
                          Curve (AUC)
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Calculating
                          similarity based on Euclidean/Manhattan Distance
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Calculation of
                          Entropy and Information Gain
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Calculation of
                          Gini Index
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Basic
                          Probability
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Conditional
                          Probability
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Naïve Bayes
                          Theorem
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Multiplication
                          rule for dependent and independent events
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Differential
                          Equations and Partial Derivations
                        </li>
                        <li>
                          <i className="bx bx-chevron-right"></i> Linear
                          Algebra:
                          <ul>
                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              Correlation, Covariance
                            </li>
                            <li>
                              <i className="bx bx-chevron-right"></i> Matrices
                              and Vectors
                            </li>
                            <li>
                              <i className="bx bx-chevron-right"></i> Inverse
                              and Transpose of Matrices
                            </li>
                            <li>
                              <i className="bx bx-chevron-right"></i> Eigen
                              Values and Eigen Vectors
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <strong>Machine Learning using Python:</strong>
                      <ul>
                        <li>
                          <i className="bx bx-chevron-right"></i>{" "}
                          <strong>Regression:</strong>
                          <ul>
                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>Linear Regression:</strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i> What
                                  is Regression?
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Types
                                  of Regression
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Model
                                  Description
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Ordinary Least Square method
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Import
                                  and Read the Data
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Perform Exploratory Data Analysis
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Interpreting Model Coefficients
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Feature Selection
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Training and Testing the data
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Model
                                  Evaluation Using Train/Test Split
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Training the model
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Predicting Test data
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Model
                                  Evaluation Metrics for Regression
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  <strong>
                                    Use Case – Linear Regression using
                                    Advertising Dataset and Housing Dataset
                                  </strong>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>Logistic Regression:</strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Introduction
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Data
                                  Exploration
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Data
                                  Visualization
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Feature Selection (Recursive Feature
                                  Elimination)
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Implementing the Model
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Logistic Regression Model Fitting
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Predicting Test Set Results and Calculate
                                  Accuracy
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Cross
                                  Validation
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Confusion Matrix
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Compute Precision, Recall, F-Measure and
                                  Support
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> ROC
                                  Curve (Receiver Operating Characteristic)
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Classification Report
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Logistic Regression Hypothesis
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  <strong>
                                    Use Case – Logistic Regression using Banking
                                    dataset
                                  </strong>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>K-Nearest Neighbor:</strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Understanding classification using Nearest
                                  Neighbor
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Find
                                  K-Nearest Neighbors
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Rescale using min-max normalization
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Diagnosing cancer with K-NN algorithm
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Import/Load Data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Exploring and Preparing the data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Transformation – Normalizing numeric data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Data preparation – creating training and
                                      Test dataset
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Training a model on the data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Evaluating model performance
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Improve model performance
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>Support Vector Machine (SVM):</strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Goal
                                  of Support Vector Machine (SVM)
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Support Vector Machine – Basics
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Advantages and Disadvantages of SVMs
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Hyperplane and Margin
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Classification with Hyperplanes
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Linear
                                  Separable Case
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Non-Separable Case
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Linear
                                  SVM
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> Kernel
                                  and Radial Functions
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Constructing the Maximal Margin Classifier
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  <strong>
                                    Use Case – SVM using cancer dataset
                                  </strong>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>Decision Tree and Random Forest:</strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Understanding decision trees
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Calculation of Entropy and Information
                                      Gain
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Choosing the best split
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Pruning the decision tree
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Collect data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Exploring and preparing the data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Training a model on the data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Evaluating model performance
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Improving model performance
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Boosting the accuracy of decision trees
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i> What
                                  is Random Forest algorithm?
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Advantages of Random Forest algorithm
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  <strong>
                                    Use Case – Decision Tree and Random Forestin
                                    Medicine
                                  </strong>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>
                                PROBABILISTIC LEARNING – CLASSIFICATION USING
                                NAÏVE BAYES:
                              </strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Understanding naïve Bayes
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Basic concepts of Bayesian methods
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Probability
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Joint probability
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Conditional probability with Bayes’
                                      theorem
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <i className="bx bx-chevron-right"></i> The
                                  naïve Bayes algorithm
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      The naïve Bayes classification
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Using numeric features with naïve Bayes
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <i className="bx bx-chevron-right"></i> Naïve
                                  Bayes algorithm Example
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Collecting data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Exploring and preparing the data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Training a model on the data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Evaluating model performance
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Improving model performance
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>

                            <li>
                              <i className="bx bx-chevron-right"></i>{" "}
                              <strong>
                                FINDING GROUPS OF DATA- CLUSTERING WITH K-MEANS:
                              </strong>
                              <ul>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Understanding clustering
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Clustering as a machine learning task
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      The K-means algorithm for clustering
                                      <ul>
                                        <li>
                                          <i className="bx bx-chevron-right"></i>{" "}
                                          Using distance to assign and update
                                          cluster
                                        </li>
                                        <li>
                                          <i className="bx bx-chevron-right"></i>{" "}
                                          Choosing the appropriate number of
                                          cluster
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>

                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Finding segments using K-means clustering
                                  <ul>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Collecting data
                                    </li>
                                    <li>
                                      <i className="bx bx-chevron-right"></i>{" "}
                                      Exploring and preparing the data
                                      <ul>
                                        <li>
                                          <i className="bx bx-chevron-right"></i>{" "}
                                          Data preparation – dummy coding
                                          missing values
                                        </li>
                                        <li>
                                          <i className="bx bx-chevron-right"></i>{" "}
                                          Data preparing – imputing missing
                                          values
                                          <ul>
                                            <li>
                                              <i className="bx bx-chevron-right"></i>{" "}
                                              Training a model on the data
                                            </li>
                                            <li>
                                              <i className="bx bx-chevron-right"></i>{" "}
                                              Evaluating model performance
                                            </li>
                                            <li>
                                              <i className="bx bx-chevron-right"></i>{" "}
                                              Improving model performance
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Principal Component Analysis (PCA)
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  Dimensionality Reduction
                                </li>
                                <li>
                                  <i className="bx bx-chevron-right"></i>{" "}
                                  <strong>
                                    Use Case – KMeans Clustering using Wholesale
                                    Customers dataset
                                  </strong>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i>{" "}
                            <strong>
                              DIMENSIONALITY REDUCTION AND VISUALIZATION
                            </strong>
                            <ul>
                              <li>
                                <i className="bx bx-chevron-right"></i> What is
                                Dimensionality reduction?
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Row
                                Vector and Column Vector
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> How to
                                represent a data set?
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> How to
                                represent a dataset as a Matrix.
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Data
                                Pre-processing: Feature Normalisation
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Mean of
                                a data matrix
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Data
                                Pre-processing: Column Standardization
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Co-variance of a Data Matrix
                              </li>
                            </ul>
                          </li>
                        </ul>

                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i>{" "}
                            <strong>PCA(PRINCIPAL COMPONENT ANALYSIS)</strong>
                            <ul>
                              <li>
                                <i className="bx bx-chevron-right"></i> Why
                                learn PCA?
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Geometric intuition of PCA
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Mathematical objective function of PCA
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Eigen
                                values and Eigen vectors (PCA): Dimensionality
                                reduction
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> PCA for
                                Dimensionality Reduction and Visualization
                              </li>
                            </ul>
                          </li>
                        </ul>

                        <ul>
                          <li>
                            <i className="bx bx-chevron-right"></i>{" "}
                            <strong>Deep Learning</strong>
                            <ul>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Introduction to Deep Learning
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Building
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Neural
                                Networks Architecture
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Convolutional Neural Networks (CNN)
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i>{" "}
                                Artificial Neural Networks (ANN)
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Deep
                                Learning with Keras
                              </li>
                              <li>
                                <i className="bx bx-chevron-right"></i> Image
                                Classification with Keras
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </ul>
                    </li>
                  </ul>
                  {/* End of PYTHON */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Data Science Section --> */}
      </React.Fragment>
    );
  }
}
