import React, { Component } from "react";
import PropTypes from "prop-types";
// import MainCarousel from './Carousel';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import Hero from "./Hero";
import ExpertiseSection from "./ExpertiseSection";
import expertiseData from "./data/expertise.json";
import solutionData from "./data/solutions.json";
import testimonialData from "./data/testimonials.json";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SolutionCard from "./SolutionCard";
import Testimonial from "./Testimonial";

const tempData = [
  {
    title: "Analytics",
    description: "Analytics involves the systematic computational analysis and interpretation of data to find meaningful patterns.",
    image: "/assets/img/more-services-1.jpg"
  },
  {
    title: "AI & ML Development",
    description: "Businesses prioritize marketing investments to personalize and analyze rapidly increasing data.",
    image: "/assets/img/more-services-3.jpg"
  },
  {
    title: "DevOps",
    description: "Effective DevOps requires metrics for progress, documenting success, and identifying improvement areas.",
    image: "/assets/img/more-services-4.jpg"
  },
  {
    title: "Python",
    description: "Choosing a programming language significantly affects the future of software projects.",
    image: "/assets/img/more-services-5.jpeg"
  },
  {
    title: "Big Data",
    description: "Competitors use data analysis strategies for competition, innovation, and capturing value.",
    image: "/assets/img/more-services-7.jpg"
  },
  {
    title: "Cloud",
    description: "Software allows running programs online, storing/accessing content, and serverless development/testing.",
    image: "/assets/img/more-services-8.jpg"
  },
];

// Custom arrow components for the slider
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalState: false,
    };

    this.handleShow = this.handleShow.bind(this);
  }

  handleShow() {
    this.setState({ modalState: !this.state.modalState });
  }

  render() {
    const sliderSettings = {
      dots: true, // Shows dot navigation at the bottom
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: true, // Enable automatic transition
      autoplaySpeed: 5000, // Time each slide remains visible
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };

    return (
      <React.Fragment>
        {/* Modal Start */}
        <div
          className={
            "modal fade" + (this.state.modalState ? " show d-block" : " d-none")
          }
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <img
                  src="/assets/img/poster.jpg"
                  className="img-fluid animated"
                  alt=""
                />
                <button
                  type="button"
                  style={{ position: "relative", right: 20, bottom: 20 }}
                  className="close"
                  onClick={this.handleShow}
                >
                  <span>&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Modal End */}
        <div
          style={
            this.state.modalState ? { filter: "blur(5px) grayscale(90%)" } : {}
          }
        >
          <Hero />

          {/* <!-- ======= Counts Section ======= --> */}
          <section id="counts" className="counts mt-3">
            <div className="section-title" style={{ paddingBottom: 0, marginTop: "20px" }} data-aos="fade-up">
              <h2>Who We Are</h2>
              <p>
                Detayuno Analytics - Pioneers in Data Engineering, DevOps, and Machine Learning Excellence.
              </p>
            </div>
            <div className="container">
              <div className="row">
                <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
                  <img src="assets/img/counts-img.svg" alt="" className="img-fluid" />
                </div>

                <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                  <div className="content d-flex flex-column justify-content-center">
                    <div className="row">
                      <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i className="icofont-simple-smile"></i>
                          <span data-toggle="counter-up">65</span>
                          <p><strong>Happy Clients</strong> Our success is measured by results, the most important being how our clients feel about their experience with us. Happy customers and ongoing relationships are ...</p>
                        </div>
                      </div>

                      <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i className="icofont-document-folder"></i>
                          <span data-toggle="counter-up">85</span>
                          <p><strong>Projects</strong> To be the leader in Telecom Consulting, Projects & Services by serving the clients and creating value for them through our quality of work .</p>
                        </div>
                      </div>

                      <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i className="icofont-clock-time"></i>
                          <span data-toggle="counter-up">12</span>
                          <p><strong>Years of experience</strong> The team comes over with 15+ years of experienec and zeal to work effectively.</p>
                        </div>
                      </div>

                      <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                          <i className="icofont-award"></i>
                          <span data-toggle="counter-up">15</span>
                          <p><strong>Awards</strong>I will first highlight some of the key international and global technology award schemes and share some of the key information in terms of their history, categories and deadlines. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End .content--> */}
                </div>
              </div>
            </div>
          </section>
          {/* <!-- End Counts Section --> */}

          <main id="main">
            {/* <!-- ======= More Services Section ======= --> */}
            <section id="more-services" className="more-services">
              <div className="section-title" style={{ paddingBottom: 0 }} data-aos="fade-up">
                <h2>Area of Experties</h2>
                <p>The team excels in strategically scaling and enhancing businesses.</p>
              </div>
              {/* {expertiseData.map((expertise) => ( */}
              {/* <div className="container" style={{ marginTop: 50 }} key={expertise.title}> */}
              {/* <div className="section-subtitle">
                    <h3>{expertise.title}</h3>
                    <p>{expertise.description}</p>
                  </div> */}
              {/* <Slider {...sliderSettings}> */}
              {/* { */}
              {/* expertise.items.map((item) => ( */}
              {/* <div key={item.title} className="px-3"> */}
              {/* <div */}
              {/* className="card" */}
              {/* style={{ */}
              {/* backgroundImage: `url(${item.image})`, */}
              {/* backgroundSize: 'contain', */}
              {/* backgroundPosition: 'center', */}
              {/* backgroundRepeat: 'no-repeat', */}
              {/* minHeight: 350, */}
              {/* }} */}
              {/* data-aos="fade-up" */}
              {/* data-aos-delay="100" */}
              {/* > */}
              {/* <div className="card-body"> */}
              {/* <h5 className="card-title">{item.title}</h5> */}
              {/* <p className="card-text">{item.description}</p> */}
              {/* <div className="read-more"></div> */}
              {/* Optional Read More link */}
              {/* <div className="read-more"><a href="#"><i className="icofont-arrow-right"></i> Read More</a></div> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* )) */}
              {/* } */}
              {/* </Slider> */}
              {/* </div> */}

              <div className="container" style={{ marginTop: 50 }}>
                <Slider {...sliderSettings}>
                  {
                    tempData.map((item) => (
                      <div key={item.title} className="px-3">
                        <div
                          className="card"
                          style={{
                            backgroundImage: `url(${item.image})`,
                            minHeight: 300,
                          }}
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          <div className="card-body">
                            <h5 className="card-title">{item.title}</h5>
                            <p className="card-text">{item.description}</p>
                            <div className="read-more"></div>
                            {/* Optional Read More link */}
                            {/* <div className="read-more"><a href="#"><i className="icofont-arrow-right"></i> Read More</a></div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </Slider>
              </div>
              {/* )) */}
              {/* } */}
            </section>
            {/* <!-- End More Services Section --> */}

            {/* <!-- ======= Solutions Section ======= --> */}
            <section id="solutions" className="solutions">
              <div className="section-title" data-aos="fade-up">
                <h2>Our Solutions</h2>
                <p>Our intention to provide excellent customer service.</p>
              </div>
              <div className="container">
                <div className="d-flex flex-wrap justify-content-center align-items-center" style={{
                  gap: 20,
                }}>
                  {
                    solutionData.map((solution) => (
                      <SolutionCard key={solution.title} {...solution} />
                    ))
                  }
                </div>
              </div>
            </section>
            {/* <!-- End Solutions Section --> */}

            {/* <!-- ======= Clients Section ======= --> */}
            <section id="clients" className="clients clients">
              <div className="section-title" data-aos="fade-up">
                <h2>Our Customers</h2>
                <p>Our intention to provide excellent customer service.</p>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-6">
                    <img
                      src="/assets/img/clients/client-1.png"
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-in"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-6">
                    <img
                      src="assets/img/clients/client-2.png"
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-in"
                      data-aos-delay="100"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-6">
                    <img
                      src="assets/img/clients/client-3.png"
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-in"
                      data-aos-delay="200"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-6">
                    <img
                      src="assets/img/clients/client-4.png"
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-in"
                      data-aos-delay="300"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-6">
                    <img
                      src="assets/img/clients/client-5.png"
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-in"
                      data-aos-delay="400"
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-6">
                    <img
                      src="assets/img/clients/client-6.png"
                      className="img-fluid"
                      alt=""
                      data-aos="zoom-in"
                      data-aos-delay="500"
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- End Clients Section --> */}

            {/* <!-- ======= Testimonials Section ======= --> */}
            <section id="testimonials" className="testimonials section-bg">
              <div className="container">
                <div className="section-title" data-aos="fade-up">
                  <h2>Testimonials</h2>
                  <p>
                    Testimonials are written or recorded statements that support
                    your credibility and level of expertise.
                  </p>
                </div>

                <div
                  className="owl-carousel testimonials-carousel"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {testimonialData.map((testimonial, index) => (
                    <Testimonial key={index} testimonial={testimonial} />
                  ))}
                </div>
              </div>
            </section>
            {/* <!-- End Testimonials Section --> */}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  index: PropTypes.string,
};
